"use client"
import { Button } from "@nextui-org/react"
import * as React from "react"
import { ExclamationCircleIcon } from "@heroicons/react/24/outline"
import { tv } from "tailwind-variants"
import { useTranslation } from "react-i18next"

import Paper from "@/components/Paper/Paper"
import { formatBusinessRegistrationNumber } from "@/utils/formats"
import { TextDivider } from "@/components/Dividers"
import { cn } from "@/utils/styles"
import { Tag } from "@/components/Tag"

export type WorkspaceJoinByCertificationProps = {
  workspaceName: string
  representativeName: string
  businessRegistrationNumber: string
  onClickCertification: () => void
  type: "need" | "success"
}

const variants = tv({
  slots: {
    container: "",
    workspaceName: "typography-body-1-medium truncate",
    infoBlock: "rounded-lg bg-[#F9F9F9] px-4 py-3",
    infoGroupRow: "flex flex-col sm:flex-row sm:items-center gap-2",
    infoRow: "flex items-center gap-2",
    infoLabel: "typography-body-2-medium text-gray-08",
    infoValue: "typography-body-3-regular",
    button: "w-full",
    noticeBlock: [
      "mt-4 text-sm text-gray-08",
      "px-4 py-3",
      "rounded-lg bg-[#F9F9F9]",
    ],
    noticeRow: "flex items-start gap-1",
    noticeIcon: "mt-[2px] size-5",
    noticeText: "typography-body-2-medium",
  },
})

export function WorkspaceJoinByCertification({
  workspaceName,
  representativeName,
  businessRegistrationNumber,
  onClickCertification,
  type,
}: WorkspaceJoinByCertificationProps) {
  const { t } = useTranslation()
  const {
    container,
    workspaceName: workspaceNameClass,
    infoBlock,
    infoRow,
    infoGroupRow,
    infoLabel,
    infoValue,
    button,
    noticeBlock,
    noticeRow,
    noticeIcon,
    noticeText,
  } = variants()

  return (
    <div className={container()}>
      <Paper>
        <Paper.Body>
          <div className="flex justify-between">
            <div className={workspaceNameClass()}>{workspaceName}</div>
            <WorkspaceTag type={type} />
          </div>

          <blockquote className={cn(infoBlock(), "mt-3")}>
            <div className={infoGroupRow()}>
              <div className={infoRow()}>
                <div className={infoLabel()}>
                  {t("auth.workspace.certification.representative")}
                </div>
                <div className={infoValue()}>{representativeName}</div>
              </div>
              <TextDivider variant="vertical" />
              <div className={infoRow()}>
                <div className={infoLabel()}>
                  {t("auth.workspace.certification.businessNumber")}
                </div>
                <div className={infoValue()}>
                  {formatBusinessRegistrationNumber(businessRegistrationNumber)}
                </div>
              </div>
            </div>
          </blockquote>

          <Button
            size="lg"
            color="secondary"
            className={cn(button(), "mt-4")}
            onClick={onClickCertification}
          >
            {t("auth.workspace.certification.button")}
          </Button>
        </Paper.Body>
      </Paper>

      <blockquote className={noticeBlock()}>
        <div className={noticeRow()}>
          <ExclamationCircleIcon className={noticeIcon()} />
          <p className={noticeText()}>{t("auth.workspace.certification.notice")}</p>
        </div>
      </blockquote>
    </div>
  )
}

function WorkspaceTag({ type }: { type: "need" | "success" }) {
  const { t } = useTranslation()

  if (type === "success") {
    return (
      <Tag intent="success">
        {t("auth.workspace.certification.successCertification")}
      </Tag>
    )
  }

  return (
    <Tag intent="primary">{t("auth.workspace.certification.needCertification")}</Tag>
  )
}
