import { TFunction } from "i18next"

import { ApiErrorBody, BoltaApiErrorResponse } from "./types"
import { isApiErrorResponse } from "./isApiErrorResponse"

export const toApiErrorMessage =
  (t: TFunction) =>
  <ErrorBody extends ApiErrorBody>(error: BoltaApiErrorResponse<ErrorBody>) => {
    const unexpectedMessage = t("error.apis.unexpected")
    const apiErrorBody = toApiErrorBody(error)
    if (apiErrorBody) {
      return apiErrorBody?.message || unexpectedMessage
    }
    if (error instanceof Error) {
      return error?.message || unexpectedMessage
    }
    return unexpectedMessage
  }

export const toApiErrorBody = <ErrorBody extends ApiErrorBody>(
  error: BoltaApiErrorResponse<ErrorBody>,
) => {
  if (isApiErrorResponse(error)) {
    return error.response?.data?.body
  }
  return null
}
