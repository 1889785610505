import { instance } from "@/app/(dashboard)/(sidebar)/api/api"
import { Account } from "@/clients/bolta-apis"
import { BaseResponse } from "@/clients/types"

import { apiPrivateClient } from "../apiClients"

export const postSendPasswordResetAuthenticationCodeApi = async (
  requestBody: Account.SendPasswordResetAuthenticationCode.RequestBody,
): Promise<boolean> => {
  try {
    await instance({
      method: "POST",
      url: "/api/v1/public/accounts:sendPasswordResetAuthenticationCode",
      data: requestBody,
    })
    return true
  } catch (error) {
    return false
  }
}

export const postSendEmailVerifyAuthenticationCodeApi = async (
  requestBody: Account.SendEmailVerifyAuthenticationCode.RequestBody,
): Promise<boolean> => {
  try {
    await instance({
      method: "POST",
      url: "/api/v1/public/accounts:sendEmailVerifyAuthenticationCode",
      data: requestBody,
    })
    return true
  } catch (error) {
    return false
  }
}

export const postConfirmEmailVerifyAuthenticationCodeApi = async (
  requestBody: Account.ConfirmEmailVerifyAuthenticationCode.RequestBody,
) => {
  const {
    data: { body },
  } = await instance<
    BaseResponse<Account.ConfirmEmailVerifyAuthenticationCode.ResponseBody>
  >({
    method: "POST",
    url: "/api/v1/public/accounts:confirmEmailVerifyAuthenticationCode",
    data: requestBody,
  })
  return body.token
}

export const postConfirmPasswordResetAuthenticationCodeApi = async (
  requestBody: Account.ConfirmPasswordResetAuthenticationCode.RequestBody,
) => {
  const {
    data: { body },
  } = await instance<
    BaseResponse<Account.ConfirmPasswordResetAuthenticationCode.ResponseBody>
  >({
    method: "POST",
    url: "/api/v1/public/accounts:confirmPasswordResetAuthenticationCode",
    data: requestBody,
  })
  return body.token
}

export const signUpApi = async (
  data,
  invitationCode: string | null,
): Promise<boolean> => {
  const url = `/api/v1/public/accounts:signUp${
    invitationCode ? `?invitationCode=${invitationCode}` : ""
  }`

  try {
    await instance.post(url, data)

    return true
  } catch (error) {
    return false
  }
}

export type SignInApi = {
  Params: {
    email: string
    password: string
  }
  Response: {
    isNew: boolean
    accountId: number
  }
}

export const signInApi = async ({
  email,
  password,
}: SignInApi["Params"]): Promise<SignInApi["Response"]> => {
  const url = "/api/v1/public/accounts:signIn"

  const { data } = await apiPrivateClient<BaseResponse<SignInApi["Response"]>>({
    method: "POST",
    url,
    data: {
      email,
      password,
    },
  })
  return data.body
}

// 로그인 - 비밀번호 찾기
export const putResetPasswordApi = async (
  password: string,
  token: string,
): Promise<boolean> => {
  const url = "/api/v1/public/accounts:resetPassword"

  try {
    await instance.put(url, { password, token })

    return true
  } catch (error) {
    return false
  }
}

// 설정: 비밀번호 변경
export const putChangePasswordApi = async (
  { accountId }: Account.ResetPassword1.RequestParams,
  requestBody: Account.ResetPassword1.RequestBody,
): Promise<boolean> => {
  try {
    await instance<BaseResponse<Account.ResetPassword1.ResponseBody>>({
      method: "PUT",
      url: `/api/v1/accounts/${accountId}:resetPassword`,
      data: requestBody,
    })
    return true
  } catch (error) {
    return false
  }
}

export const putAccountApi = async ({
  accountId,
  ...requestBody
}: Account.UpdateAccountProfile.RequestParams &
  Account.UpdateAccountProfile.RequestBody) => {
  const {
    data: { body },
  } = await instance<BaseResponse<Account.UpdateAccountProfile.ResponseBody>>({
    method: "PUT",
    url: `/api/v1/accounts/${accountId}`,
    data: requestBody,
  })
  return body
}
