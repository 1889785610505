/**
 * @deprecated use formatCurrency instead
 * 세자리마다 콤마(,)를 추가하는 함수
 */
export const formatNumber = (
  num: number | string | null | undefined,
  noNegative?: boolean,
): string => {
  if (num === "-") {
    return noNegative ? "" : num
  }

  if (num === 0) {
    return "0"
  }

  if (!num || num === null || num === undefined) {
    return ""
  }

  let formattedNum = num
  let isNegative = false

  if (typeof num === "string") {
    // 숫자, 쉼표, 마이너스 기호만 포함되어 있는지 확인
    if (!/^[-0-9,]+$/.test(num)) {
      return "" // 유효하지 않은 형식일 경우 빈 문자열 반환
    }

    // 음수인지 확인
    if (num.startsWith("-")) {
      isNegative = true
      formattedNum = num.substring(1) // '-' 기호 제거
    }

    formattedNum = formattedNum.toString().replace(/,/g, "")
    formattedNum = Number(formattedNum)
  } else if (typeof num === "number" && num < 0) {
    // 숫자 타입이고 음수일 경우
    isNegative = true
    formattedNum = Math.abs(num) // 음수를 양수로 변환
  }

  // 숫자를 문자열로 변환하고 천 단위마다 쉼표 추가
  const result = formattedNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

  // NaN일 경우 빈 문자열 반환
  if (result === "NaN") {
    return ""
  }

  // 음수일 경우 앞에 '-' 추가
  return isNegative && !noNegative ? `-${result}` : result
}

export const removeComma = (str: string): string => {
  return str.replace(/,/g, "")
}

/**
 * 사업자등록번호
 * 000-00-00000
 * @deprecated use ./src/utils/format/formatBusinessRegistrationNumber.ts instead
 */
export const formatBusinessRegistrationNumber = (value: string, max = 10) => {
  if (!value) return value

  const numbers = value.replace(/[^\d]/g, "")

  let parts = [] as string[]
  if (numbers.length > 3) {
    parts.push(numbers.slice(0, 3))
    if (numbers.length > 5) {
      parts.push(numbers.slice(3, 5))
      parts.push(numbers.slice(5, max || 10))
    } else {
      parts.push(numbers.slice(3))
    }
  } else {
    parts = [numbers]
  }

  return parts.join("-")
}

// 전화번호
// 000-0000-0000
export const formatPhoneNumber = (value: string) => {
  if (!value) return value

  const numbers = value.replace(/[^\d]/g, "")

  let parts = [] as string[]
  if (numbers.length > 3) {
    parts.push(numbers.slice(0, 3))
    if (numbers.length > 7) {
      parts.push(numbers.slice(3, 7))
      parts.push(numbers.slice(7, 11))
    } else {
      parts.push(numbers.slice(3))
    }
  } else {
    parts = [numbers]
  }

  return parts.join("-")
}

/**
 *
 * @param value "010-1234-5678"
 * @returns "01012345678"
 */
export const toPhoneNumber = (value: string): string => {
  return value.replace(/[^0-9]/g, "")
}

// 승인번호
// 00000000-00000000-00000aaa
export const formatApprovalNumber = (value: string) => {
  if (!value) return value

  // -를 제외한 숫자와 문자만 남깁니다.
  const validChars = value.replace(/-/g, "")

  let parts = [] as string[]
  if (validChars.length > 8) {
    parts.push(validChars.slice(0, 8))
    if (validChars.length > 16) {
      parts.push(validChars.slice(8, 16))
      parts.push(validChars.slice(16, 24))
    } else {
      parts.push(validChars.slice(8))
    }
  } else {
    parts = [validChars]
  }

  return parts.join("-")
}

// 크레딧카드번호
// 0000-0000-0000-0000
export const formatCreditCardNumber = (value: string) => {
  if (!value) return value

  // 숫자와 별표만 남깁니다.
  const validCharacters = value.replace(/[^\d*]/g, "")

  const parts = [] as string[]
  for (let i = 0; i < validCharacters.length; i += 4) {
    // 4자리씩 끊어서 parts 배열에 추가합니다.
    parts.push(validCharacters.slice(i, i + 4))
  }

  return parts.join("-")
}
