import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline"
import { Button } from "@nextui-org/react"
import * as React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { useRouter, useSearchParams } from "next/navigation"

import LoginIcon1 from "@/assets/icons/login-icon-1.svg"
import LoginIcon2 from "@/assets/icons/login-icon-2.svg"
import { signInApi } from "@/clients/accounts/apis"
import TextField from "@/components/TextField/TextField"
import { LoginForm } from "@/features/auth/types"
import { BoltaEvent } from "@/utils/mixpanels"
import { useProfileStore } from "@/store/profiles"
import { useSnackbarStore } from "@/store/snackbarStore"
import { toApiErrorMessage } from "@/clients/toApiErrorMessage"

import { rules } from "./validations"

export function EmailLogin() {
  const { t } = useTranslation()
  const router = useRouter()
  const searchParams = useSearchParams()
  const workspaceId = useProfileStore(state => state.getCurrentWorkspaceId())
  const openSnackbar = useSnackbarStore(state => state.openSnackbar)
  const [isPasswordVisible, setIsPasswordVisible] = React.useState(false)
  const [isCapsLock, setIsCapsLock] = React.useState(false)

  const methods = useForm<LoginForm>({
    defaultValues: {
      email: "",
      password: "",
    },
  })

  const { mutate: signInMutate, isPending: isSignInPending } = useMutation({
    mutationFn: signInApi,
  })

  const handleLogin = async (signinValues: LoginForm) => {
    BoltaEvent.track("clicked__email_login", {
      workspaceId,
    })
    signInMutate(signinValues, {
      onSuccess: res => {
        const { accountId, isNew } = res
        const nextSearchParams = new URLSearchParams(searchParams.toString())
        if (accountId) {
          nextSearchParams.set("accountId", accountId.toString())
        }
        if (isNew !== undefined) {
          nextSearchParams.set("isNew", isNew.toString())
        }
        nextSearchParams.set("authorizedMethod", "email")
        const url = `/login-success?${nextSearchParams.toString()}`
        router.push(url)
      },
      onError: error => {
        const errorMessage = toApiErrorMessage(t)(error)
        openSnackbar(errorMessage, "danger")
      },
    })
  }

  const onSubmit = async (signinValues: LoginForm) => {
    if (isSignInPending) {
      return
    }

    handleLogin(signinValues)
  }

  return (
    <>
      <FormProvider {...methods}>
        <TextField
          autoComplete="email"
          name="email"
          className="bolta-input"
          placeholder="업무용 이메일"
          rules={rules.email}
          nextFocus="password"
          startContent={<LoginIcon1 className="shrink-0" />}
        />
        <TextField
          autoComplete="current-password"
          name="password"
          type={isPasswordVisible ? "" : "password"}
          placeholder="비밀번호"
          className="bolta-input mb-1 mt-3"
          rules={rules.password}
          startContent={<LoginIcon2 className="shrink-0" />}
          endContent={
            <a
              className="cursor-pointer"
              onClick={() => setIsPasswordVisible(!isPasswordVisible)}
            >
              {isPasswordVisible ? (
                <EyeIcon className="w-6 text-[#CED1D7]" />
              ) : (
                <EyeSlashIcon className="w-6 text-[#CED1D7]" />
              )}
            </a>
          }
          onKeyUp={e => setIsCapsLock(e.getModifierState("CapsLock"))}
          onKeyDown={e => {
            if (e.key === "Enter") {
              methods.handleSubmit(onSubmit)()
            }
          }}
        />

        {isCapsLock && (
          <div className="mb-4 text-[13px] text-primary">
            Caps Lock이 켜져 있습니다.
          </div>
        )}
      </FormProvider>

      <Button
        size="lg"
        color="primary"
        className="mt-3 w-full !rounded-md"
        onClick={methods.handleSubmit(onSubmit)}
      >
        로그인
      </Button>
    </>
  )
}
