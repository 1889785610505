/**
 * 자동정렬하면 언어별 라인수차이로 인해 가독성 및 놓치는 메세지를 인지하기 어렵습니다.
 * 따라서 prettier의 max-len을 무시하기 위해 주석을 추가합니다. (mex-len만 별도로 끄는게 불가능)
 */
/* eslint-disable prettier/prettier */
import { MessageGroup } from "../i18n.types"

const messages: MessageGroup = {
  "error.apis.unexpected": "알 수 없는 오류가 발생했습니다.",
  "error.apis.noPermission": "해당 기능을 이용 할 수 있는 권한이 없습니다.",
  "error.page.500": "예기치 못한 에러가 발생했습니다.",
  "error.invalidEmail": "올바르지 않은 이메일 형식입니다.",

  "currency.won": "{{value}}원",
  "currency.millions": "{{value}}만원",
  "currency.billions": "{{value}}억원",

  "count.max": "최대 {{count}}개",
  "count.min": "최소 {{count}}개",
  "files.upload.error": "파일 업로드 중 오류가 발생했습니다.",

  "invitations.modal.title": "초대 대기",
  "invitations.modal.description": "아직 워크스페이스에 참여하지 않은 팀원이에요.",
  "invitations.list.total": "전체 {{count}}건",
  "invitations.list.empty": "초대 대기 중인 팀원이 없습니다.",
  "invitations.status.pending": "초대중",
  "invitations.table.header.status": "상태",
  "invitations.table.header.email": "이메일",
  "invitations.table.header.role": "초대권한",
  "invitations.action.resend": "메일 재전송",
  "invitations.action.cancel": "초대 취소",
  "invitations.cancel.title": "팀원 초대를 취소할까요?",
  "invitations.cancel.description": "취소된 팀원은 기존 이메일로 가입할 수 없으며,\n새로 초대해야 합니다.",
  "invitations.cancel.confirm": "네, 취소할게요.",
  "invitations.snackbar.resent": "팀원 초대 메일을 재전송했습니다.",
  "invitations.snackbar.canceled": "팀원 초대를 취소했습니다.",
  "certification.success.register": "인증서가 등록되었습니다.",
  "certification.success.join": "인증서를 통해 워크스페이스에 참여했습니다.",
  "certification.error.businessNumber": "다른 사업자등록번호로 발급된 인증서입니다.\n다른 인증서로 등록해 주세요.",
  "certification.error.taxInvoice": "세금계산서 발행이 가능한 인증서로 등록해 주세요.",
  "certification.error.individual": "사업자용 인증서를 등록해 주세요.",
  "certification.error.unexpected": "예상하지 못한 오류가 발생했습니다. 지속적으로 문제 발생 시 문의해 주세요.",
  "auth.workspace.certification.needCertification": "인증 필요",
  "auth.workspace.certification.successCertification": "인증 완료",
  "auth.workspace.certification.representative": "대표자명",
  "auth.workspace.certification.businessNumber": "사업자등록번호",
  "auth.workspace.certification.button": "공동인증서 인증",
  "auth.workspace.certification.notice": "공동인증서 인증을 할 수 없는 팀원이라면,\n워크스페이스에서 '초대권한'을 가진 팀원에게 초대를 요청해 주세요.",
  "workspace.certification.badge": "인증필요",
  "workspace.certification.representative": "대표자명",
  "workspace.certification.businessNumber": "사업자등록번호",
  "workspace.certification.button": "공동인증서 인증",
  "workspace.certification.notice": "공동인증서 인증을 할 수 없는 팀원이라면,\n워크스페이스에서 '초대권한'을 가진 팀원에게 초대를 요청해 주세요.",

  "customer.form.title.create": "고객 정보 등록",
  "customer.form.title.edit": "고객 정보",
  "customer.form.subtitle": "사업자등록증 기준으로 정보를 입력해 주세요.",
  "customer.form.required": "필수 정보",
  "customer.form.organizationName": "회사명",
  "customer.form.representativeName": "대표자명",
  "customer.form.businessRegistrationNumber": "사업자등록번호",
  "customer.form.businessRegistrationNumber.placeholder": "000-00-00000",
  "customer.form.additional": "추가 정보",
  "customer.form.address": "사업장 주소",
  "customer.form.address.placeholder": "서울특별시 관악구 관악로",
  "customer.form.taxRegistrationId": "종사업장 번호",
  "customer.form.taxRegistrationId.tooltip": "사업자등록증에 기재되어 있는 경우에 입력해 주세요.",
  "customer.form.taxRegistrationId.placeholder": "4자리 숫자",
  "customer.form.businessType": "업태",
  "customer.form.businessType.placeholder": "정보통신업",
  "customer.form.businessItem": "종목",
  "customer.form.businessItem.placeholder": "소프트웨어 개발 및 공급업",
  "customer.form.memo": "고객 메모",
  "customer.form.memo.placeholder": "메모 내용을 입력해 주세요.",
  "customer.form.staff": "담당자 정보",
  "customer.form.staff.title": "담당자{{index}}",
  "customer.form.staff.delete": "삭제",
  "customer.form.staff.email": "이메일",
  "customer.form.staff.email.placeholder": "이메일",
  "customer.form.staff.name": "이름",
  "customer.form.staff.name.placeholder": "이름",
  "customer.form.staff.telephone": "휴대전화번호",
  "customer.form.staff.telephone.placeholder": "숫자만 입력",
  "customer.form.staff.add": "담당자 추가",
  "customer.form.cancel": "취소",
  "customer.form.submit.create": "등록",
  "customer.form.submit.edit": "저장",
  "customer.form.error.loading": "데이터 로딩 중 오류가 발생했습니다.",
  "customer.created.success": "고객 정보가 등록되었습니다",
  "customer.updated.success": "고객 정보가 저장되었습니다",

  "plan.usageBar.refresh.message": "이번 달 사용량이 모두 소진되었습니다.\n{{date}}에 사용량이 초기화됩니다.",
  "plan.usageBar.title": "사용량",
  "plan.usageBar.unlimited": "무제한",
  "plan.usageBar.legend.issuanceCount": "발행",
  "plan.usageBar.legend.issuanceRequestCount": "요청"
}

export default messages
