import { MutationOptions, useMutation, useQueryClient } from "@tanstack/react-query"

import { logout } from "@/clients/auth"
import { ApiErrorResponse } from "@/clients/types"
import { useProfileStore } from "@/store/profiles"
import { BoltaEvent } from "@/utils/mixpanels"
import { profileQueries } from "@/clients/profiles"

type LogoutMutationOptions = MutationOptions<void, ApiErrorResponse>
type LogoutMutationOnSuccess = LogoutMutationOptions["onSuccess"]
type LogoutMutationOnError = LogoutMutationOptions["onError"]
type LogoutMutationOnSettled = LogoutMutationOptions["onSettled"]

interface UseLogoutMutationOptions {
  onSuccess?: LogoutMutationOnSuccess
  onError?: LogoutMutationOnError
  onSettled?: LogoutMutationOnSettled
}

export function useLogoutMutationEffect({
  onSuccess,
  onError,
  onSettled,
}: UseLogoutMutationOptions = {}) {
  const workspaceId = useProfileStore(state => state.getCurrentWorkspaceId())
  const setProfile = useProfileStore(state => state.setProfile)
  const setWorkspaceGroup = useProfileStore(state => state.setWorkspaceGroup)
  const queryClient = useQueryClient()

  const commonHandler = async () => {
    setProfile(null)
    setWorkspaceGroup(null)
    BoltaEvent.logout({ workspaceId })
    await queryClient.resetQueries({
      queryKey: profileQueries.profile().queryKey,
      exact: true,
    })
  }

  const handleSuccess: LogoutMutationOnSuccess = async (...args) => {
    await commonHandler()
    onSuccess?.(...args)
  }

  const handleError: LogoutMutationOnError = async (...args) => {
    await commonHandler()
    onError?.(...args)
  }

  return useMutation({
    mutationFn: logout,
    onSuccess: handleSuccess,
    onError: handleError,
    onSettled,
  })
}
