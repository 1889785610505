// 사업자등록번호
export const formatBusinessRegistrationNumber = (value: string, max = 10) => {
  if (!value) return value

  const numbers = value.replace(/[^\d]/g, "")

  let parts = [] as string[]
  if (numbers.length > 3) {
    parts.push(numbers.slice(0, 3))
    if (numbers.length > 5) {
      parts.push(numbers.slice(3, 5))
      parts.push(numbers.slice(5, max || 10))
    } else {
      parts.push(numbers.slice(3))
    }
  } else {
    parts = [numbers]
  }

  return parts.join("-")
}

export const unformatBusinessRegistrationNumber = (value: string) => {
  return value.replace(/-/g, "")
}
