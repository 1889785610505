import axios, { InternalAxiosRequestConfig } from "axios"
import qs from "qs"

const isServer = () => typeof window === "undefined"

const overwriteCookies = async (config: InternalAxiosRequestConfig) => {
  const { cookies } = await import("next/headers")
  const existsCookies = cookies()
    .getAll()
    .map(cookie => `${cookie.name}=${cookie.value}`)
    .join(";")
  if (existsCookies) {
    config.headers.cookie = existsCookies
  }
}

/**
 * withCredentials 옵션이 없는 기본 apiClient
 * - Public API 호출에 사용합니다.
 * - 인증없이 사용이 가능한 API에 사용합니다.
 */
const apiPublicClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  headers: {
    Accept: "application/json",
  },
  withCredentials: true,
})

apiPublicClient.defaults.paramsSerializer = function (paramObj) {
  return qs.stringify(paramObj, {
    arrayFormat: "repeat",
  })
}

/**
 * withCredentials 옵션이 있는 apiClient
 * - Dashboard 내부에서 Profile/Workspace이 수반되는 경우에 사용합니다.
 */
const apiPrivateClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  headers: {
    Accept: "application/json",
  },
  withCredentials: true,
})

apiPrivateClient.defaults.paramsSerializer = function (paramObj) {
  return qs.stringify(paramObj, {
    arrayFormat: "repeat",
  })
}

apiPrivateClient.interceptors.request.use(async config => {
  if (isServer()) {
    await overwriteCookies(config)
  }
  return config
})

export { apiPrivateClient, apiPublicClient }
