/**
 * @todo 다국어 사용시 Date plugin의 format customizing이 필요함
 */
export const DATE_FORMAT = {
  DATE_ISO8601: "YYYY-MM-DD",
  DATE: "YYYY.MM.DD",
  DATETIME: "YYYY.MM.DD HH:mm",
  FULL_DATETIME: "YYYY.MM.DD HH:mm:ss",
  TIME: "HH:mm",
  TODAY_DATE: "오늘",
  TODAY_DATETIME: "오늘 HH:mm",
  TODAY_FULL_DATETIME: "오늘 HH:mm:ss",
}
