import { QueryClient, QueryCache, MutationCache } from "@tanstack/query-core"
import * as React from "react"
import { useRouter } from "next/navigation"

import { useProfileStore } from "@/store/profiles"

import { isApiErrorResponse } from "./isApiErrorResponse"
import { logout } from "./auth"

export function useCreateQueryClientCreator() {
  const router = useRouter()
  const setProfile = useProfileStore(state => state.setProfile)
  const setWorkspaceGroup = useProfileStore(state => state.setWorkspaceGroup)

  const commonHandler = async () => {
    setProfile(null)
    setWorkspaceGroup(null)
    router.replace("/login")
  }

  const [queryClient] = React.useState<QueryClient>(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          retry: false,
          refetchOnWindowFocus: false,
        },
      },
      queryCache: new QueryCache({
        // onError: error => {
        //   const isApiError = isApiErrorResponse(error)
        //   if (isApiError) {
        //     /**
        //      * 모든 API의 호출하기 전에 각 Layer에서 profile query를 호출하여 session 여부를 확인하기 때문에 profileQuery 기준으로 redirection을 처리합니다.
        //      */
        //     switch (error.status) {
        //       case 401: {
        //         logout().finally(() => commonHandler())
        //         break
        //       }
        //     }
        //   }
        // },
      }),
      mutationCache: new MutationCache({
        onError: error => {
          const isApiError = isApiErrorResponse(error)
          if (isApiError) {
            switch (error.status) {
              case 401: {
                logout().finally(() => commonHandler())
                break
              }
            }
          }
        },
      }),
    })
  })

  return queryClient
}
