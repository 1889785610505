import { find, pipe } from "@fxts/core"
import * as React from "react"

import { useProfileStore } from "@/store/profiles"
import { ProfileModel } from "@/features/profiles"

export const useMatchedWorkspace = () => {
  const workspaceId = useProfileStore(state => state.getCurrentWorkspaceId())

  const getMatchedWorkspaceGroup = React.useCallback(
    (profile: ProfileModel) => {
      const workspaceGroup = pipe(
        profile.workspaces,
        find(workspaceGroup => workspaceGroup.workspace.id === workspaceId),
      )
      if (workspaceGroup) {
        return workspaceGroup
      }
      return profile.workspaces[0]
    },
    [workspaceId],
  )

  return {
    getMatchedWorkspaceGroup,
  } as const
}
