type RedirectPublicPurchaseInvoiceCertificationOptions = {
  workspaceId: number
  invoiceId: number
  redirectUrl: string
  action: "approve" | "reject"
}

/**
 * (역발행) 매입세금계산서 간편요청에서 익명유저의 승인/거절 시 인증서 발급 페이지로 이동하여 아래 2 API를 인증서 Module에서 호출합니다.
 * - rejectPurchaseTaxInvoiceRequestApi,
 * - approvePurchaseTaxInvoiceRequestApi,
 * 그러므로, API를 FE에서 직접 호출할 필요는 없으며, 인증서의 redirectUrl과 페이지 분기만 잘 구현해주면 됩니다.
 */
export const redirectPublicPurchaseInvoiceCertification = ({
  workspaceId,
  invoiceId,
  redirectUrl,
  action = "approve",
}: RedirectPublicPurchaseInvoiceCertificationOptions) => {
  const requestType =
    action === "approve"
      ? "approveInvoiceIssuanceWithCertificate"
      : "rejectInvoiceIssuanceWithCertificate"
  const url = `${process.env.NEXT_PUBLIC_API_URL}/MagicLine4Web/ML4Web/certificate.html`
  const info = btoa(
    JSON.stringify({
      redirectUrl,
      workspaceId,
      requestType,
      invoiceId,
    }),
  )
  window.location.href = `${url}?info=${info}`
}
