import { map, pipe, toArray } from "@fxts/core"

import { ProfileModel } from "@/features/profiles"
import { formatBusinessRegistrationNumber, formatPhoneNumber } from "@/utils/formats"
import { formatTaxRegistrationId } from "@/utils/format"
import { AuthorizedFeatureRole } from "@/features/auth"

import { GetProfileApi } from "./apis"

export const toSelectProfileModel = <T extends GetProfileApi["Response"]>(
  data: T,
): ProfileModel => {
  return {
    ...data,
    workspaces: pipe(
      data.workspaces,
      map(workspaceGroup => ({
        ...workspaceGroup,
        collaborator: {
          ...workspaceGroup.collaborator,
          authorizedFeatures: workspaceGroup.collaborator.authorizedFeatures.map(
            feature => feature as AuthorizedFeatureRole,
          ),
        },
        workspace: {
          ...workspaceGroup.workspace,
          businessRegistrationNumber: formatBusinessRegistrationNumber(
            workspaceGroup.workspace.businessRegistrationNumber,
          ),
          staff: {
            ...workspaceGroup.workspace.staff,
            telephone: formatPhoneNumber(
              workspaceGroup.workspace.staff.telephone || "",
            ),
          },
          taxRegistrationId: formatTaxRegistrationId(
            workspaceGroup.workspace.taxRegistrationId || "",
          ),
        },
      })),
      toArray,
    ),
  }
}

export function selectCurrentWorkspaceInfo(
  profile: ProfileModel,
  workspaceId: number,
) {
  return profile.workspaces.find(workspaceGroup => {
    return workspaceGroup.workspace.id === workspaceId
  })
}
