import * as React from "react"
import { isNil } from "@fxts/core"

import {
  useQueryEffect,
  UseQueryEffectCallbackOptions,
} from "@/clients/useQueryEffect"
import { ProfileModel } from "@/features/profiles"
import { useProfileStore } from "@/store/profiles"
import { ApiErrorResponse } from "@/clients/types"
import { useMatchedWorkspace } from "@/features/workspaces"

import { profileQueries } from "../queries"

export function useProfileQueryEffect({
  onError,
  onSuccess,
  onSettled,
}: UseQueryEffectCallbackOptions<ProfileModel> = {}) {
  const setProfile = useProfileStore(state => state.setProfile)
  const setWorkspaceGroup = useProfileStore(state => state.setWorkspaceGroup)
  const [status, setStatus] = React.useState<number | undefined>(undefined)

  const { getMatchedWorkspaceGroup } = useMatchedWorkspace()

  const handleSuccess = React.useCallback(
    (profile: ProfileModel) => {
      setProfile(profile)
      const validWorkspaceGroup = getMatchedWorkspaceGroup(profile)
      if (validWorkspaceGroup) {
        setWorkspaceGroup(validWorkspaceGroup)
      }
      onSuccess?.(profile)
      setStatus(undefined)
    },
    [onSuccess, setProfile, setWorkspaceGroup, getMatchedWorkspaceGroup],
  )

  const handleError = React.useCallback(
    (e: ApiErrorResponse) => {
      setStatus(e.status)
      onError?.(e)
    },
    [onError],
  )

  const response = useQueryEffect({
    ...profileQueries.profile(),
    enabled: isNil(status),
    onError: handleError,
    onSuccess: handleSuccess,
    onSettled,
  })

  return response
}
