/**
 * 자동정렬하면 언어별 라인수차이로 인해 가독성 및 놓치는 메세지를 인지하기 어렵습니다.
 * 따라서 prettier의 max-len을 무시하기 위해 주석을 추가합니다. (mex-len만 별도로 끄는게 불가능)
 */
/* eslint-disable prettier/prettier */
import { MessageGroup } from "../i18n.types"

const messages: MessageGroup = {
  "validation.required": "필수 입력 항목입니다.",
  "validation.maxLength": "{{length}}자 이내로 작성해 주세요",
  "validation.min": "{{min}}개 이상 입력 가능합니다.",
  "validation.max": "최대 {{max}}까지 입력 가능합니다.",
  "validation.maxAmount": "최대 {{amount}}까지 입력 가능합니다.",
  "validation.pattern": "숫자를 입력해 주세요.",
  "validation.nonZero": "0원은 입력할 수 없습니다.",
  "validation.maxItems": "최대 {{max}}개까지 입력 가능합니다.",
  "validation.maxFiles": "최대 {{max}}개까지 첨부 가능합니다.",
  "validation.email": "올바르지 않은 이메일 형식입니다.",
}

export default messages
