/**
 * 자동정렬하면 언어별 라인수차이로 인해 가독성 및 놓치는 메세지를 인지하기 어렵습니다.
 * 따라서 prettier의 max-len을 무시하기 위해 주석을 추가합니다. (mex-len만 별도로 끄는게 불가능)
 */
/* eslint-disable prettier/prettier */
import { MessageGroup } from "../i18n.types"

const messages: MessageGroup = {
  "purchase-tax-invoice.new.steps.writing": "정보 작성",
  "purchase-tax-invoice.new.steps.preview": "정보 확인",
  "purchase-tax-invoice.new.steps.completed": "요청 완료",
  "purchase-tax-invoice.list.title": "세금계산서 발행 요청",
  "purchase-tax-invoice.list.description": "받아야 할 세금계산서를 직접 요청할 수 있습니다.",
  "purchase-tax-invoice.list.newButton": "간편요청",
  "purchase-tax-invoice.list.waiting.empty.message": "대기중인 요청이 없습니다.",
  "purchase-tax-invoice.list.completed.empty.message": "승인완료한 요청이 없습니다.",
  "purchase-tax-invoice.list.empty.button": "간편요청",
  "purchase-tax-invoice.list.tabs.waiting": "대기중",
  "purchase-tax-invoice.list.tabs.approved": "승인완료",
  "purchase-tax-invoice.table.bulk.cancel": "발행취소",
  "purchase-tax-invoice.table.status": "상태",
  "purchase-tax-invoice.table.customerName": "거래처명",
  "purchase-tax-invoice.table.itemName": "품목명",
  "purchase-tax-invoice.table.assignees": "담당자 이메일",
  "purchase-tax-invoice.table.totalAmount": "발행금액(부가세포함)",
  "purchase-tax-invoice.table.createdAt": "생성일자",
  "purchase-tax-invoice.supplier.label": "공급자",
  "purchase-tax-invoice.receiver.label": "공급받는자",
  "purchase-tax-invoice.organizationName": "회사명",
  "purchase-tax-invoice.representative": "대표자명",
  "purchase-tax-invoice.businessRegistrationNumber": "사업자등록번호",
  "purchase-tax-invoice.title": "발행 정보",
  "purchase-tax-invoice.trade-date": "거래일자",
  "purchase-tax-invoice.description": "공급받는자가 발행 요청한 세금계산서 정보입니다.",
  "purchase-tax-invoice.status": "상태",
  "purchase-tax-invoice.status.noMember": "회원정보 없음",
  "purchase-tax-invoice.status.noCertification": "공동인증서가 등록되어 있지 않습니다.",
  "purchase-tax-invoice.confirm": "정보 확인",
  "purchase-tax-invoice.tooltip.authenticated": "인증서 로그인 없이, 바로 승인할 수 있어요!",
  "purchase-tax-invoice.tooltip.unauthenticated": "비회원은 승인 시, 공동인증서 인증이 필요해요.",
  "purchase-tax-invoice.unavailable.expiredDate": "<bold>{{date}}</bold>까지 승인되지 않으면 자동으로 요청이 취소돼요.",
  "purchase-tax-invoice.unavailable.chip": "안내",
  "purchase-tax-invoice.writeDate": "거래일자",
  "purchase-tax-invoice.summary.approve": "승인",
  "purchase-tax-invoice.summary.businessRegistrationNumber": "사업자등록번호",
  "purchase-tax-invoice.summary.companyName": "회사명",
  "purchase-tax-invoice.summary.writeDate": "거래일자(작성일자)",
  "purchase-tax-invoice.summary.reject": "거절",
  "purchase-tax-invoice.summary.representative": "대표자명",
  "purchase-tax-invoice.summary.taxAmount": "세액",
  "purchase-tax-invoice.summary.title": "발행정보 확인",
  "purchase-tax-invoice.summary.description": "정보 확인 후 승인버튼을 눌러 세금계산서를 발행해 주세요.",
  "purchase-tax-invoice.summary.totalAmount": "총액",
  "purchase-tax-invoice.summary.totalSupplyCost": "공급가액",
  "purchase-tax-invoice.summary.organizationName": "회사명",
  "purchase-tax-invoice.notification.title": "공동인증서 안내",
  "purchase-tax-invoice.notification.description": "비회원인 경우 승인 시 공동인증서가 필요해요.\n<highlight>볼타</highlight>에 가입하신 후 공동인증서 등록해두면, 다음부터\n인증서 로그인 없이 승인하실 수 있습니다.",
  "purchase-tax-invoice.form.writing.title": "정보 작성",
  "purchase-tax-invoice.form.writing.description": "거래정보와 추가 전달사항을 작성해 주세요.",
  "purchase-tax-invoice.form.customer.title": "요청하는 거래처가 어디인가요?",
  "purchase-tax-invoice.form.customer": "거래처",
  "purchase-tax-invoice.form.customer.placeholder": "기존 거래처를 선택하거나 신규로 추가해 주세요.",
  "purchase-tax-invoice.form.assignee": "담당자",
  "purchase-tax-invoice.form.assignee.placeholder": "담당자를 입력해 주세요.",
  "purchase-tax-invoice.form.customer.edit.message": "정보 수정이 필요한가요?",
  "purchase-tax-invoice.form.requestedAt.title": "거래한 날짜가 언제인가요?",
  "purchase-tax-invoice.form.requestedAt.date": "거래일자(작성일자)",
  "purchase-tax-invoice.form.requestedAt.placeholder": "YYYY.MM.DD",
  "purchase-tax-invoice.form.requestedAt.notice.label": "안내",
  "purchase-tax-invoice.form.requestedAt.notice.deadlineDate": "<highlight>{{date}}</highlight>까지 승인되지 않으면 자동으로 요청이 취소돼요",
  "purchase-tax-invoice.form.requestedAt.notice.deadlineDate.tooltip": "거래일자 기준 발행마감일이 지난 경우\n요청이 자동으로 취소됩니다.",
  "purchase-tax-invoice.form.items.title": "공급받은 품목이 무엇인가요?",
  "purchase-tax-invoice.form.items.add": "추가",
  "purchase-tax-invoice.form.items.remove": "삭제",
  "purchase-tax-invoice.form.items.item": "품목",
  "purchase-tax-invoice.form.items.itemNumber": "품목 {{number}}",
  "purchase-tax-invoice.form.items.name": "품목명",
  "purchase-tax-invoice.form.items.name.placeholder": "품목명을 입력해 주세요.",
  "purchase-tax-invoice.form.items.purchasedAt": "공급일",
  "purchase-tax-invoice.form.items.unitPrice": "단가",
  "purchase-tax-invoice.form.items.quantity": "수량",
  "purchase-tax-invoice.form.items.tax": "세액",
  "purchase-tax-invoice.form.items.totalPrice": "합계금액",
  "purchase-tax-invoice.form.submit": "다음",
  "purchase-tax-invoice.form.additionalInfo.title": "추가로 전달할 사항이 있나요?",
  "purchase-tax-invoice.form.additionalInfo.memo": "전달 사항",
  "purchase-tax-invoice.form.additionalInfo.memo.placeholder": "전달할 사항을 작성해 주세요",
  "purchase-tax-invoice.form.additionalInfo.attachments": "증빙자료",
  "purchase-tax-invoice.form.additionalInfo.attachments.description": "(PNG,JPG,PDF파일 / 최대 3개)",
  "purchase-tax-invoice.form.preview.title": "정보 확인",
  "purchase-tax-invoice.form.preview.description": "요청 내용 확인 후 정보 확인을 눌러 발행 요청을 해주세요.",
  "purchase-tax-invoice.form.preview.items.etc": "외 {{count}}개",
  "purchase-tax-invoice.form.preview.subTitle": "요청 내용",
  "purchase-tax-invoice.form.preview.supplier.title": "공급자 정보",
  "purchase-tax-invoice.form.preview.supplier.organizationName": "상호명",
  "purchase-tax-invoice.form.preview.supplier.representativeName": "대표자명",
  "purchase-tax-invoice.form.preview.supplier.businessRegistrationNumber": "사업자등록번호",
  "purchase-tax-invoice.form.preview.supplier.assignee": "담당자",
  "purchase-tax-invoice.form.preview.supplier.address": "사업장 주소",
  "purchase-tax-invoice.form.preview.supplier.taxRegistrationId": "종사업장 번호",
  "purchase-tax-invoice.form.preview.supplier.businessType": "업태",
  "purchase-tax-invoice.form.preview.supplier.businessItem": "종목",
  "purchase-tax-invoice.form.preview.transaction.title": "거래 정보",
  "purchase-tax-invoice.form.preview.transaction.requestedAt": "거래일자(작성일자)",
  "purchase-tax-invoice.form.preview.transaction.items.title": "품목",
  "purchase-tax-invoice.form.preview.transaction.items.etc": "외 {{count}}개",
  "purchase-tax-invoice.form.preview.transaction.itemPrice": "공급가액",
  "purchase-tax-invoice.form.preview.transaction.tax": "세액",
  "purchase-tax-invoice.form.preview.transaction.totalPrice": "합계금액",
  "purchase-tax-invoice.form.preview.additional.title": "추가 정보",
  "purchase-tax-invoice.form.preview.additional.memo": "전달사항",
  "purchase-tax-invoice.form.preview.additional.attachments": "증빙자료",
  "purchase-tax-invoice.form.preview.preview.title": "세금계산서 미리보기",
  "purchase-tax-invoice.form.preview.preview.description": "발행 요청을 누르면 공급자에게 승인 요청 이메일이 발송됩니다.",
  "purchase-tax-invoice.form.preview.preview.cancel": "취소",
  "purchase-tax-invoice.form.preview.preview.submit": "발행 요청",
  "purchase-tax-invoice.form.preview.navigation.prev": "이전",
  "purchase-tax-invoice.form.preview.navigation.confirm": "정보 확인",
  "purchase-tax-invoice.completed.description": "<highlight>{{email}}</highlight> 이메일로\n세금계산서 발행 요청 내용을 전달했습니다.",
  "purchase-tax-invoice.completed.title": "간편요청 완료",
  "purchase-tax-invoice.completed.list": "요청 목록",
  "purchase-tax-invoice.completed.add": "추가 요청",
  "purchase-tax-invoice.detail.title": "요청 상세",
  "purchase-tax-invoice.detail.delete": "내역 삭제",
  "purchase-tax-invoice.detail.confirm.delete.title": "요청 삭제",
  "purchase-tax-invoice.detail.confirm.delete.success": "요청 삭제가 완료되었습니다.",
  "purchase-tax-invoice.detail.confirm.delete.description": "삭제된 요청은 더 이상 확인할 수 없습니다.",
  "purchase-tax-invoice.detail.confirm.delete.confirmText": "네, 삭제할게요",
  "purchase-tax-invoice.detail.confirm.cancel.success": "요청 취소가 완료되었습니다.",
  "purchase-tax-invoice.detail.cancel": "요청 취소",
  "purchase-tax-invoice.detail.confirm.cancel.title": "요청 취소",
  "purchase-tax-invoice.detail.confirm.cancel.description": "취소된 요청은 더 이상 확인할 수 없습니다.",
  "purchase-tax-invoice.detail.confirm.cancel.confirmText": "네, 취소할게요",
  "purchase-tax-invoice.detail.resendEmail": "이메일 재전송",
  "purchase-tax-invoice.detail.modal.resendEmail.title": "이메일 재전송",
  "purchase-tax-invoice.detail.modal.resendEmail.description": "세금계산서 정보를 재전송 할 수 있습니다.",
  "purchase-tax-invoice.detail.modal.resendEmail.input.label": "이메일",
  "purchase-tax-invoice.detail.modal.resendEmail.input.placeholder": "이메일을 입력해 주세요.",
  "purchase-tax-invoice.detail.modal.resendEmail.error.maxEmail": "최대 {{count}}명까지 입력 가능합니다.",
  "purchase-tax-invoice.detail.modal.resendEmail.button.cancel": "취소",
  "purchase-tax-invoice.detail.modal.resendEmail.button.confirm": "재전송",
  "purchase-tax-invoice.detail.confirm.resendEmail.confirm.success": "이메일 재전송이 완료되었습니다.",
  "purchase-tax-invoice.detail.resendEmail.error.emptyEmail": "이메일을 입력해 주세요.",
  "purchase-tax-invoice.detail.content.title": "작성 내용",
  "purchase-tax-invoice.detail.content.viewDetail": "상세보기",
  "purchase-tax-invoice.detail.content.organizationName": "상호명",
  "purchase-tax-invoice.detail.content.businessRegistrationNumber": "사업자등록번호",
  "purchase-tax-invoice.detail.content.assigneeEmail": "담당자 이메일",
  "purchase-tax-invoice.detail.content.requestedAt": "작성일자",
  "purchase-tax-invoice.detail.content.itemPrice": "공급가액",
  "purchase-tax-invoice.detail.content.tax": "세액",
  "purchase-tax-invoice.detail.content.totalPrice": "합계 금액",
  "purchase-tax-invoice.detail.content.receipt": "(영수)",
  "purchase-tax-invoice.detail.content.invoice": "(청구)",
  "purchase-tax-invoice.detail.additional.title": "추가 사항",
  "purchase-tax-invoice.detail.additional.memo": "전달사항",
  "purchase-tax-invoice.detail.additional.attachments": "증빙자료",
  "purchase-tax-invoice.detail.status.pending": "승인대기",
  "purchase-tax-invoice.detail.status.pendingMessage": "요청승인 대기 중",
  "purchase-tax-invoice.detail.status.unavailable.title": "유효하지 않은 요청입니다.",
  "purchase-tax-invoice.detail.status.unavailable.message": "접근할 수 없는 요청입니다.\n요청이 삭제되었거나 만료되었습니다.",
  "purchase-tax-invoice.detail.notification.memo": "전달사항",
  "purchase-tax-invoice.detail.notification.attachments": "증빙자료",
  "purchase-tax-invoice.detail.items.title": "품목",
  "purchase-tax-invoice.detail.items.empty": "품목이 없습니다.",
  "purchase-tax-invoice.detail.items.name": "품목명",
  "purchase-tax-invoice.detail.items.quantity": "수량",
  "purchase-tax-invoice.detail.items.price": "단가",
  "purchase-tax-invoice.detail.items.supplyCost": "공급가액",
  "purchase-tax-invoice.detail.items.tax": "세액",
  "purchase-tax-invoice.detail.items.totalPrice": "합계",
  "purchase-tax-invoice.detail.preview.title": "세금계산서 미리보기",
  "purchase-tax-invoice.detail.preview.description": "발행 요청을 누르면 공급자에게 승인 요청 이메일이 발송됩니다.",
  "purchase-tax-invoice.detail.preview.cancel": "취소",
  "purchase-tax-invoice.detail.preview.submit": "발행 요청",
  "purchase-tax-invoice.summary.reject.success": "발행요청이 거절되었습니다.",
  "purchase-tax-invoice.approved.authenticated.title": "매출세금계산서 발행 완료",
  "purchase-tax-invoice.approved.authenticated.description": "세금계산서가 국세청에 전송되었습니다.\n발행된 세금계산서는 발행목록에서 확인하실 수 있습니다.",
  "purchase-tax-invoice.approved.authenticated.button": "매출세금계산서 확인",
  "purchase-tax-invoice.approved.anonymous.title": "매출세금계산서 발행 완료",
  "purchase-tax-invoice.approved.anonymous.description": "세금계산서 발행이 완료되었습니다.\n홈택스 전송까지 최대 15분까지 소요됩니다.",
  "purchase-tax-invoice.approved.anonymous.button": "회원가입하고 간편 승인하기",
  "purchase-tax-invoice.approved.anonymous.info": "<highlight>볼타</highlight>에 가입하고 인증서를 등록하시면,\n다음부터는 인증서 없이 간편하게 승인할 수 있습니다.",
  "purchase-tax-invoice-request.status.REQUEST_APPROVED.badge": "요청승인",
  "purchase-tax-invoice-request.status.REQUEST_APPROVED.message": "세금계산서 정보는 <button>'매입 세금계산서'</button>에서 확인해 주세요.",
  "purchase-tax-invoice-request.status.REQUEST_PENDING.badge": "승인대기",
  "purchase-tax-invoice-request.status.REQUEST_PENDING.message": "요청승인 대기 중",
  "purchase-tax-invoice-request.status.REQUEST_REJECTED.badge": "요청거절",
  "purchase-tax-invoice-request.status.REQUEST_REJECTED.message": "공급자가 요청을 거절했습니다.",
  "purchase-tax-invoice-request.status.REQUEST_CANCELED.badge": "요청취소",
  "purchase-tax-invoice-request.status.REQUEST_CANCELED.message": "요청이 취소되었습니다.",
  "purchase-tax-invoice.not-registered.message": "공급자 워크스페이스에 속하지 않은 계정입니다. 계정을 확인해주세요.",
  "purchase-tax-invoice.not-registered.logout": "로그아웃",
  "purchase-tax-invoice.reject.message": "발행요청이 거절되었습니다."
}

export default messages
