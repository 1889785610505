"use client"

import "@/libs/dayjs"

import { NextUIProvider } from "@nextui-org/react"
import { QueryClientProvider } from "@tanstack/react-query"
import dayjs from "dayjs"
import { usePathname } from "next/navigation"
import * as React from "react"
import { I18nextProvider } from "react-i18next"

import Snackbar from "@/components/Snackbar/Snackbar"
import { useTailwindBreakpoint } from "@/hooks/useTailwindBreakpoint"
import { createI18n } from "@/i18n"
import {
  ChannelTalk,
  GoogleTagManager,
  MicrosoftClarity,
  MixpanelProvider,
} from "@/providers"
import { useBreakpointStore } from "@/store/layoutStore"
import { BoltaEvent } from "@/utils/mixpanels"
import { useProfileStore } from "@/store/profiles/store"
import { useCreateQueryClientCreator } from "@/clients"

export function Provider({ children }: { children: React.ReactNode }) {
  const workspaceId = useProfileStore(state => state.getCurrentWorkspaceId())
  const pathname = usePathname()
  const breakpoint = useTailwindBreakpoint()
  const setBreakpoint = useBreakpointStore(state => state.setBreakpoint)
  const queryClient = useCreateQueryClientCreator()
  const [i18n] = React.useState(() => createI18n("ko"))

  React.useEffect(() => {
    setBreakpoint(breakpoint)
  }, [breakpoint, setBreakpoint])

  React.useEffect(() => {
    dayjs.locale("ko")
    dayjs.tz.setDefault("Asia/Seoul")
  }, [])

  React.useEffect(() => {
    BoltaEvent.trackPageView({ workspaceId })
  }, [pathname, workspaceId])

  return (
    <>
      <GoogleTagManager id="GTM-59ZRC8ZJ" />
      <MicrosoftClarity id="jl1n8d8te5" />
      <ChannelTalk />
      <MixpanelProvider token={process.env.NEXT_PUBLIC_MIXPANEL_TOKEN} />
      <QueryClientProvider client={queryClient}>
        <React.Suspense>
          <I18nextProvider i18n={i18n}>
            <NextUIProvider>
              <Snackbar />
              {children}
            </NextUIProvider>
          </I18nextProvider>
        </React.Suspense>
      </QueryClientProvider>
    </>
  )
}
