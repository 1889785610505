/**
 * 자동정렬하면 언어별 라인수차이로 인해 가독성 및 놓치는 메세지를 인지하기 어렵습니다.
 * 따라서 prettier의 max-len을 무시하기 위해 주석을 추가합니다. (mex-len만 별도로 끄는게 불가능)
 */
/* eslint-disable prettier/prettier */
import { MessageGroup } from "../i18n.types"

const messages: MessageGroup = {
  "settings.workspace.title": "사업자 정보",
  "settings.workspace.additionalInfo": "추가 정보",
  "settings.workspace.staffInfo.title": "담당자 정보",
  "settings.workspace.staffInfo.description": "세금계산서 발행 시 입력되는 담당자 정보입니다.",
  "settings.workspace.fields.organizationName.label": "회사명",
  "settings.workspace.fields.organizationName.placeholder": "회사명",
  "settings.workspace.fields.representativeName.label": "대표자명",
  "settings.workspace.fields.representativeName.placeholder": "대표자명",
  "settings.workspace.fields.businessRegistrationNumber.label": "사업자등록번호",
  "settings.workspace.fields.address.label": "사업장 주소",
  "settings.workspace.fields.address.placeholder": "서울특별시 관악구 관악로",
  "settings.workspace.fields.taxRegistrationId.label": "종사업장 번호",
  "settings.workspace.fields.taxRegistrationId.placeholder": "4자리 숫자",
  "settings.workspace.fields.taxRegistrationId.tooltip": "사업자등록증에 기재되어 있는 경우에 입력해 주세요.",
  "settings.workspace.fields.businessType.label": "업태",
  "settings.workspace.fields.businessType.placeholder": "정보통신업",
  "settings.workspace.fields.businessItem.label": "종목",
  "settings.workspace.fields.businessItem.placeholder": "소프트웨어 개발 및 공급업",
  "settings.workspace.fields.staff.email.label": "이메일",
  "settings.workspace.fields.staff.email.placeholder": "이메일",
  "settings.workspace.fields.staff.displayName.label": "이름",
  "settings.workspace.fields.staff.displayName.placeholder": "이름",
  "settings.workspace.fields.staff.telephone.label": "휴대전화번호",
  "settings.workspace.fields.staff.telephone.placeholder": "숫자만입력",
  "settings.workspace.submit": "저장",
  "settings.workspace.messages.success": "회사 정보가 저장되었습니다.",
  "settings.workspace.messages.noPermission": "수정 권한이 없습니다."
}

export default messages
