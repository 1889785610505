import { apiPrivateClient } from "@/clients/apiClients"
import { BaseResponse } from "@/clients/types"
import { Profile } from "@/clients/bolta-apis"

export type GetProfileApi = {
  Response: Profile.GetProfile.ResponseBody
}

export async function getProfileApi() {
  const {
    data: { body },
  } = await apiPrivateClient<BaseResponse<GetProfileApi["Response"]>>({
    method: "GET",
    url: "/api/v1/profiles",
  })
  return body
}
