import AlertTriangleIcon from "@/assets/font-icons/alert-triangle.svg"
import CircleCheckIcon from "@/assets/font-icons/circle-check.svg"
import CheckIcon from "@/assets/font-icons/check.svg"
import InboxIcon from "@/assets/font-icons/inbox.svg"
import LabelIcon from "@/assets/font-icons/label-icon.svg"
import PaperCheckedIcon from "@/assets/font-icons/paper-checked-icon.svg"
import SolidCircleAlertIcon from "@/assets/font-icons/solid/circle-alert.svg"
import SolidPaperCheckedIcon from "@/assets/font-icons/solid/paper-checked-icon.svg"

export {
  AlertTriangleIcon,
  CheckIcon,
  CircleCheckIcon,
  InboxIcon,
  LabelIcon,
  PaperCheckedIcon,
  SolidCircleAlertIcon,
  SolidPaperCheckedIcon,
}
