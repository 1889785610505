import { apiPrivateClient } from "@/clients/apiClients"

/**
 * 로그아웃 API
 * NOTE: logout API는 직접 구현한게 아니라 스프링부트 자체 로그아웃 엔드포인트입니다. 세션이 끊기면서 Http connection도 자동으로 끊기는거 같아요. (Body/Header가 모두 비어서 Browser에서 CORS 처리 합니다.)
 * 그러므로, catch 처리를 통해서 handling 되어야 합니다.
 */
export const logout = async () => {
  try {
    await apiPrivateClient.post("/logout")
  } catch (error) {
    // Session is terminated.
  }
}
