"use client"
import { useRouter, useSearchParams } from "next/navigation"
import * as React from "react"

import GoogleLogo from "@/assets/icons/google-logo.svg"
import Tooltip from "@/components/Tooltip/Tooltip"
import { EmailLogin } from "@/features/auth"
import { BoltaEvent } from "@/utils/mixpanels"
import { useProfileStore } from "@/store/profiles"

export default function Page() {
  const router = useRouter()
  const searchParams = useSearchParams()
  const workspaceId = useProfileStore(state => state.getCurrentWorkspaceId())
  const lastAuthorizedMethod = searchParams.get("lastAuthorizedMethod")
  /**
   * 회원가입 이후에 이동할 페이지 주소
   */
  const nextUrl = searchParams.get("nextUrl")

  const handleGoogleLogin = () => {
    const nextSearchParams = new URLSearchParams()
    if (nextUrl) {
      nextSearchParams.set("nextUrl", nextUrl)
    }
    window.location.href = `${process.env.NEXT_PUBLIC_API_URL}/bolta/oauth2/authorization/google?${nextSearchParams.toString()}`
  }

  const onClickToMoveEmailJoin = () => {
    BoltaEvent.track("clicked__sign_up", { workspaceId })

    const nextSearchParams = new URLSearchParams()
    if (nextUrl) {
      nextSearchParams.set("nextUrl", nextUrl)
    }
    router.push(`/join/email-join?${nextSearchParams.toString()}`)
  }

  return (
    <>
      <div className="mb-7 text-[28px] font-bold">로그인</div>
      <Tooltip
        content="최근 로그인"
        placement="top"
        offset={-5}
        isOpen={lastAuthorizedMethod === "google"}
      >
        <button
          className="mb-6 flex w-full cursor-pointer items-center justify-center rounded-md border border-gray-04 py-3 text-gray-10"
          onClick={() => {
            BoltaEvent.track("clicked__google_login", { workspaceId })
            handleGoogleLogin()
          }}
        >
          <GoogleLogo className="mr-2" />
          Google로 시작하기
        </button>
      </Tooltip>
      <EmailLogin />
      <div className="mt-3 flex items-center justify-end text-[15px] font-medium">
        <a
          className="cursor-pointer text-gray-08"
          onClick={() => router.push("/find-password")}
        >
          비밀번호 찾기
        </a>
        <div className="mx-3 text-[#D9D9D9]">|</div>
        <a className="cursor-pointer text-primary" onClick={onClickToMoveEmailJoin}>
          회원가입
        </a>
      </div>
    </>
  )
}
