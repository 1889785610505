import { CurrencyFormatMap, LocaleType, NumberFormatMap } from "./i18n.types"

export const LOCALES: LocaleType[] = ["ko"]

export const DEFAULT_LOCALE: LocaleType = "ko"

export const CURRENCY_FORMATS: CurrencyFormatMap = {
  ko: {
    style: "decimal", //"currency",
    currency: "KRW",
  },
}

export const NUMBER_FORMATS: NumberFormatMap = {
  ko: {
    style: "decimal",
  },
}
