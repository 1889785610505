import { QueryKey, useQuery, UseQueryOptions } from "@tanstack/react-query"
import * as React from "react"

import { useLatestValue } from "@/hooks/useLatestValue"

import { ApiErrorResponse } from "./types"

type UseQuerySuccessCallback<TData> = (data: TData) => void
type UseQueryErrorCallback<TError> = (error: TError) => void
type UseQuerySettledCallback<TData, TError> = (
  data: TData | undefined,
  error: TError | null,
) => void

export type UseQueryEffectCallbackOptions<TData, TError = ApiErrorResponse> = {
  onSuccess?: UseQuerySuccessCallback<TData>
  onError?: UseQueryErrorCallback<TError>
  onSettled?: UseQuerySettledCallback<TData, TError>
}

export type UseQueryEffectOptions<
  TQueryFnData = unknown,
  TError = ApiErrorResponse,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
> = UseQueryOptions<TQueryFnData, TError, TData, TQueryKey> & {
  onSuccess?: UseQuerySuccessCallback<TData>
  onError?: UseQueryErrorCallback<TError>
  onSettled?: UseQuerySettledCallback<TData, TError>
}

export const useQueryEffect = <
  TQueryFnData = unknown,
  TError = ApiErrorResponse,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>({
  onSuccess,
  onError,
  onSettled,
  ...options
}: UseQueryEffectOptions<TQueryFnData, TError, TData, TQueryKey>) => {
  const latestOnSuccess = useLatestValue(onSuccess)
  const latestOnError = useLatestValue(onError)
  const latestOnSettled = useLatestValue(onSettled)

  const response = useQuery<TQueryFnData, TError, TData, TQueryKey>(options)
  const { data, isSuccess, error, isError } = response

  const isSettled = isSuccess || isError

  React.useEffect(() => {
    if (isSuccess) {
      latestOnSuccess.current?.(data)
    }
  }, [data, isSuccess, latestOnSuccess])

  React.useEffect(() => {
    if (isError) {
      latestOnError.current?.(error)
    }
  }, [error, isError, latestOnError])

  React.useEffect(() => {
    if (isSettled) {
      latestOnSettled.current?.(data, error)
    }
  }, [data, error, isSettled, latestOnSettled])

  return response
}
