import { usePathname } from "next/navigation"
import * as React from "react"
import { find, keys, pipe } from "@fxts/core"

import { AuthorizedFeatureRole } from "@/features/auth/models"

const PATHNAME_PERMISSION: Record<string, AuthorizedFeatureRole> = {
  "/issuance": "TAX_INVOICE_ISSUE_REQUEST",
  "/invoices": "PURCHASED_TAX_INVOICE_FETCH",
  "/request-form": "ISSUANCE_REQUEST_FORM_FETCH",
  "/customers": "TAX_INVOICE_ISSUE_REQUEST",
  "/received-invoices": "RECEIVED_TAX_INVOICE_FETCH",
  "/received-invoices/requests": "TAX_INVOICE_ISSUANCE_REQUEST_MANAGEMENT",
  "/transactions": "BANK_ACCOUNT_TRANSACTION_FETCH",
  "/request-payment/templates": "PAYMENT_REQUEST_MANAGEMENT",
  "/request-payment/codes": "PAYMENT_REQUEST_MANAGEMENT",
  "/settings/workspace": "COMPANY_DETAIL_MANAGEMENT",
  "/settings/certification": "CERTIFICATE_MANAGEMENT",
  "/billing": "BILLING_MANAGEMENT",
  "/team/roles": "COLLABORATOR_AND_ROLE_MANAGEMENT",
  "/team/members": "COLLABORATOR_INVITATION",
}

const FALLBACK_ROUTES = {
  TAX_INVOICE_ISSUE_REQUEST: "/issuance",
  PURCHASED_TAX_INVOICE_FETCH: "/invoices",
  ISSUANCE_REQUEST_FORM_FETCH: "/request-form",
  BANK_ACCOUNT_TRANSACTION_FETCH: "/transactions",
}

const DEFAULT_FALLBACK_ROUTE = "/request-payment/requests"

/**
 * 현재 유저의 권한을 확인하여 권한을 확인하여, 권한이 없는 경우 진입을 막는다.
 */
export function useFeatureAuthorization() {
  const pathname = usePathname()

  const hasPermissionBy = React.useCallback(
    (permissions: AuthorizedFeatureRole[], role: AuthorizedFeatureRole) => {
      return permissions.some(permission => permission === role)
    },
    [],
  )

  const validatePermission = React.useCallback(
    (permissions: AuthorizedFeatureRole[]) => {
      const needsPermission = PATHNAME_PERMISSION[pathname]
      if (!needsPermission || hasPermissionBy(permissions, needsPermission)) {
        return null
      }

      const availableFallback = pipe(
        keys(FALLBACK_ROUTES),
        find(feature => hasPermissionBy(permissions, feature)),
      )

      return availableFallback
        ? FALLBACK_ROUTES[availableFallback]
        : DEFAULT_FALLBACK_ROUTE
    },
    [hasPermissionBy, pathname],
  )

  return {
    hasPermissionBy,
    validatePermission,
  } as const
}
