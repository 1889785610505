import { MessageNamespace } from "../i18n.types"
import common from "./ko.common"
import settings from "./ko.settings"
import purchaseInvoices from "./ko.purchase-invoices"
import validations from "./ko.validations"
import requestPayment from "./ko.request-payment"
const ns: MessageNamespace = {
  common: {
    ...common,
    ...settings,
    ...purchaseInvoices,
    ...validations,
    ...requestPayment,
  },
}

export default ns
