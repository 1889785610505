import { create } from "zustand"

interface LoadingOverlayState {
  isLoading: boolean
  startLoading: () => () => void
  stopLoading: () => void
}

/**
 * @deprecated OverlayLoading을 더 이상 사용하지말고, 각 페이지 별로 layout별로 처리하시기 바랍니다.
 */
export const useLoadingOverlayStore = create<LoadingOverlayState>()(set => ({
  isLoading: false,
  startLoading: () => {
    set({ isLoading: true })
    return () => set({ isLoading: false })
  },
  stopLoading: () => set({ isLoading: false }),
}))
