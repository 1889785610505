/**
 * 자동정렬하면 언어별 라인수차이로 인해 가독성 및 놓치는 메세지를 인지하기 어렵습니다.
 * 따라서 prettier의 max-len을 무시하기 위해 주석을 추가합니다. (mex-len만 별도로 끄는게 불가능)
 */
/* eslint-disable prettier/prettier */
import { MessageGroup } from "../i18n.types"

const messages: MessageGroup = {
  "request-payment.write.title": "지급요청서 작성하기",
  "request-payment.rewrite.error.invalid": "접근할 수 없는 요청서입니다.",
}

export default messages
